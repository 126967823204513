// App.js
import React, {Component} from 'react';
import {Link } from "react-router-dom";
import logo from './img/logo.png';
import { Icon } from '@iconify/react';
import './css/bootstrap.css';
import './css/home.css';

class MainNavbar extends Component {
	  constructor(props) {
		super(props);
		this.state = {
		  menu: false,
		  show: false,
		};
		
	  }

	  toggleMenu= () => {
		this.setState({ menu: !this.state.menu })
	  }
	
	 
	render(){ 
		function clickHome(){
			window.location.assign("https://www.envirogasasia.com");
		}
		const show = (this.state.menu) ? "show" : "" ;

			
    return (
		
	 <div >
	 <nav className="navbar navbar-expand-lg navbar-custom">
            <a className="logo-image" onClick={clickHome}><img src={logo} alt="EnviroGas Logo"></img></a>
		
			<button className="navbar-toggler" type="button" onClick={ this.toggleMenu }>
				<span className="navbar-toggler-icon"><Icon width = "30" icon="eva:menu-outline" /></span>
			</button>
		
            <div className={"collapse navbar-collapse " + show}>
                <ul className="navbar-nav ml-auto">
					<li className="nav-item">
						<Link to="/about-us" className="nav-link page-scroll">About Us</Link>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle page-scroll" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false">Products</a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
							<Link to="/cng" className="dropdown-item"><span className="item-text">Treated CNG</span></Link>
                            <div className="dropdown-divider"></div>
							<Link to="/oem" className="dropdown-item"><span className="item-text">OEM Products</span></Link>
                        </div>
                    </li>
					
					<li className="nav-item">
						<Link to="/partners" className="nav-link page-scroll">Partners</Link>
                    </li>
					<li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle page-scroll" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false">Gallery</a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
							<Link to="/gallery" className="dropdown-item"><span className="item-text">Photo Gallery</span></Link>
                            <div className="dropdown-divider"></div>
							<Link to="/video-gallery" className="dropdown-item"><span className="item-text">Video Gallery</span></Link>
							
                        </div>
                    </li>
					<li className="nav-item">
						<Link to="/contact" className="nav-link page-scroll">Contact Us</Link>
                    </li>
					
		
					
                   
                </ul>
				
            </div>
			
			
    </nav> 

   	</div>
  );
}
}

export {MainNavbar};