// App.js
import React, {Component} from 'react';

import './css/bootstrap.css';
import './css/product.css';
import Footer from './footer.js';
import {MainNavbar} from './navbar.js';

import image1 from './img/oem/EG-M301.jpg';
import image2 from './img/oem/EG-M302.jpg';
import image3 from './img/oem/Flow-Chart-EG-M30.jpg';

import image4 from './img/oem/EG-P01.jpg';
import image5 from './img/oem/EG-P012.jpg';
import image6 from './img/oem/EG-P0flow.jpg';

import image7 from './img/oem/EG-P15.jpg';
import image8 from './img/oem/EG-P152.jpg';
import image9 from './img/oem/Flow-Charts-EG-P15.jpg';

import image10 from './img/oem/nozzle.jpg';

class Oem extends Component {
	constructor() {
    super();
    this.state = {
		showtab:0
     
    };
  }
	showThisTab = (tabnum) => {
	  var check = !isNaN(tabnum.target.id) && (function(x) { return (x | 0) === x; })(parseFloat(tabnum.target.id))
	  if (check === true){
		  this.setState({ showtab: parseInt(tabnum.target.id) });
		  
	  }
	  
  };
	
	render(){
		
		var details = [["Gas Service", "Natural Gas"], ["Model", "EG-M30"], ["Max. Inlet Pressure (PSI)", "3000"], ["Max. Delivery Pressure (PSI)", "40"], ["Inlet Pressure Gauge", '2”4500PSI'], ["Outlet Pressure Gauge", '2”200PSI'], ["Inlet Connection", "BSP"], ["Outlet Connection", "BSP"]];
		var show = [];
		for (var i = 0; i < details.length; i++) {
			
			show.push(<div className="col-6 col-sm-3 col-md-2 col-lg-8r nospacing">
						  <div className="oemcard">
							  <div className="oemcardtitletext">{details[i][0]}</div>
							  <div className="oemcardtitle ">{details[i][1]}</div>
						  </div>
						</div>
					  )
		}
		
		var palletdetails = [["Gas Service", "Natural Gas"], ["Model", "EG-P01"], ["Max. Inlet Pressure (PSI)", "3000"], ["Max. Delivery Pressure (PSI)", "40"], ["Inlet Pressure Gauge", '2”6000PSI'], ["Outlet Pressure Gauge", '2”200PSI'], ["Inlet Connection", "BSP"], ["Outlet Connection", "BSP"]];
		var palletshow = [];
		for (var j = 0; j < palletdetails.length; j++) {
			
			palletshow.push(<div className="col-6 col-sm-3 col-md-2 col-lg-8r nospacing">
						  <div className="oemcard">
							  <div className="oemcardtitletext">{palletdetails[j][0]}</div>
							  <div className="oemcardtitle ">{palletdetails[j][1]}</div>
						  </div>
						</div>
					  )
		}
		
		var palletdetails2 = [["Gas Service", "Natural Gas"], ["Model", "EG-P15"], ["Max. Inlet Pressure (PSI)", "3000"], ["Max. Delivery Pressure (PSI)", "40"], ["Inlet Pressure Gauge", '2”4500PSI'], ["Outlet Pressure Gauge", '2”200PSI'], ["Inlet Connection", "BSP"], ["Outlet Connection", "BSP"]];
		var palletshow2 = [];
		for (var k = 0; k < palletdetails2.length; k++) {
			
			palletshow2.push(<div className="col-6 col-sm-3 col-md-2 col-lg-8r nospacing">
						  <div className="oemcard">
								  <div className="oemcardtitletext">{palletdetails2[k][0]}</div>
								  <div className="oemcardtitle ">{palletdetails2[k][1]}</div>
						  </div>
						</div>
					  )
		}
		
		


		
    return (
		
		<div>
		<div className="bodymain">
			<MainNavbar/>
			<br/>
			
		
			<div className="container">
				<div className="row">
					<div className="col-12">
						<h2 className = "titlecol">Our OEM Products</h2>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<span className = "gallerytitle">Manifold Regulators</span>
					</div>
				</div>
				<br/>
		
				<div className="row mx-auto">
					<div className="col-md-6">
						<span className = "oemtitle">EG-M30 Pressure Reducing Regulators</span><br/>
						<span className = "">EG-M30 regulators are designed to meet the requirements for the Compressed Natural Gas (CNG) industry.</span><br/><br/>
						<span className = "">EG-M30 for manifold application.</span><br/>
						<ul className="list oemlist">
							<li>Single stage</li>
							<li>Body machined from Aluminium</li>
							<li>Radiator Type design to prevent gas from freezing</li>
							<li>Maximum inlet pressure: 3000PSI</li>
							<li>Outlet pressure: 25PSI</li>
						</ul>
						
					</div>
					<div className="col-md-6 my-auto">
						<img loading="lazy" className="oemimage" src={image1} alt="Enviro Gas Manifold Regulator" />
					</div>
					
				</div>
				<div className="row mx-auto">
					<div className="col-md-6 my-auto order-md-2">
						<img loading="lazy" className="oemimage" src={image2} alt="Enviro Gas Manifold Regulator" />
					</div>
					<div className="col-md-6 pt-3 my-auto order-md-1">
						<img loading="lazy" className="oemimage" src={image3} alt="Enviro Gas Manifold Regulator" />
					</div>
				</div>
				<br/>
				<div class="row oemrow align-items-end">
					{show}
				</div>
		
				<br/><br/>
				<div className="row">
					<div className="col-12">
						<span className = "gallerytitle">Pallet Regulators</span>
					</div>
				</div>
				<br/>
		
				<div className="row mx-auto">
					<div className="col-md-6">
						<span className = "oemtitle">EG-P01 Pressure Reducing Regulators</span><br/>
						<span className = "">EG-P01 series regulators are designed to meet the requirements for the Compressed Natural Gas (CNG) industry.</span><br/><br/>
						<span className = "">EG-P01 for cylinder application</span><br/>
						<ul className="list oemlist">
							<li>Single stage</li>
							<li>Body machined from Aluminium</li>
							<li>Radiator Type design to prevent gas from freezing</li>
							<li>Maximum inlet pressure: 3000PSI</li>
							<li>Outlet pressure: 15 PSI to 40 PSI</li>
						</ul>
						
					</div>
					<div className="col-md-6 my-auto">
						<img loading="lazy" className="oemimage" src={image4} alt="Enviro Gas Pallet Regulator EG-P01" />
					</div>
					
				</div>
				<div className="row mx-auto">
					<div className="col-md-6 my-auto order-md-2">
						<img loading="lazy" className="oemimage" src={image5} alt="Enviro Gas Pallet Regulator EG-P01" />
					</div>
					<div className="col-md-6 pt-3 my-auto order-md-1">
						<img loading="lazy" className="oemimage" src={image6} alt="Enviro Gas Pallet Regulator EG-P01" />
					</div>
				</div>
				<br/>
				<div class="row oemrow align-items-end">
					{palletshow}
				</div>
		
				<br/><br/>
		
				<div className="row mx-auto">
					<div className="col-md-6">
						<span className = "oemtitle">EG-P15 Pressure Reducing Regulators</span><br/>
						<span className = "">EG-P15 series regulators are designed to meet the requirements for the Compressed Natural Gas (CNG) industry.</span><br/><br/>
						<span className = "">EG-P15 for cylinder application</span><br/>
						<ul className="list oemlist">
							<li>Single stage</li>
							<li>Body machined from Aluminium</li>
							<li>Radiator Type design to prevent gas from freezing</li>
							<li>Maximum inlet pressure: 3000PSI</li>
							<li>Outlet pressure: 21PSI to 40 PSI</li>
						</ul>
						
					</div>
					<div className="col-md-6 my-auto">
						<img loading="lazy" className="oemimage" src={image7} alt="Enviro Gas Pallet Regulator EG-P15" />
					</div>
					
				</div>
				<div className="row mx-auto">
					<div className="col-md-6 my-auto order-md-2">
						<img loading="lazy" className="oemimage" src={image8} alt="Enviro Gas Pallet Regulator EG-P15" />
					</div>
					<div className="col-md-6 pt-3 my-auto order-md-1">
						<img loading="lazy" className="oemimage" src={image9} alt="Enviro Gas Pallet Regulator EG-P15" />
					</div>
				</div>
				<br/>
				<div class="row oemrow align-items-end">
					{palletshow2}
				</div>
		
				<br/><br/>
				<div className="row">
					<div className="col-12">
						<span className = "gallerytitle">Nozzles</span>
					</div>
				</div>
				<br/>
		
				<div className="row mx-auto">
					<div className="col-md-6">
						<ul className="list oemlist">
							<li>Cutting Nozzle for Natural Gas</li>
							<li>Stainless Steel Internal Rim</li>
							<li>Galvanized Coated for improved durability</li>
						</ul>
						
					</div>
					<div className="col-md-6 my-auto">
						<img loading="lazy" className="oemimage" src={image10} alt="Enviro Gas Nozzle" />
					</div>
				</div>
				<div className="row justify-content-center">
					<div className="col-12 col-md-10 my-auto">
						<table className="table table-bordered">
						  <thead>
							<tr>
							  <th scope="col" className="oemcardtitletext">Natural Gas Torch Tip</th>
							  <th scope="col" className="oemcardtitletext">Opening Dimension (in mm)</th>
							  <th scope="col" className="oemcardtitletext">Metal Thickness</th>
							</tr>
						  </thead>
						  <tbody>
							<tr>
							  <td className="oemcardtitle">Size 2</td>
							  <td className="oemcardtitle">1.0</td>
							  <td className="oemcardtitle">1"-1&#189;"</td>
							</tr>
							<tr>
							  <td className="oemcardtitle">Size 3</td>
							  <td className="oemcardtitle">1.2</td>
							  <td className="oemcardtitle">2"-2&#189;"</td>
							</tr>
							<tr>
							  <td className="oemcardtitle">Size 4</td>
							  <td className="oemcardtitle">1.4</td>
							  <td className="oemcardtitle">3"</td>
							</tr>
							<tr>
							  <td className="oemcardtitle">Size 5</td>
							  <td className="oemcardtitle">1.7</td>
							  <td className="oemcardtitle">4"-5"</td>
							</tr>
							<tr>
							  <td className="oemcardtitle">Size 6</td>
							  <td className="oemcardtitle">2.0</td>
							  <td className="oemcardtitle">6"-8"</td>
							</tr>
						  </tbody>
						</table>
					</div>
				</div>
				
				
				
			</div> 
			
			
			
			
		</div>
		<br/>
		<Footer />
		</div>
		
		  	
  );
}}


export {Oem};
