// App.js
import React, {Component} from 'react';

import './css/bootstrap.css';
import './css/home.css';


class Footer extends Component {
	constructor() {
    super();
    this.state = {
    };
	
  }
	

	render(){ 
		var absclass = ""
		if (this.props.abs) {
			absclass = "footerabs"
		}
		
    return (
	 <div className={absclass}>
		<footer id="sticky-footer" className="flex-shrink-0 py-4">
			<div className="container text-center">
			  <small className="footer0">&copy;2023 Enviro Gas Pte Ltd. All rights reserved.</small>
		
			</div>
		</footer>
		
    </div>
  );
}}

export default Footer;