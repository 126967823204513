// App.js
import React, {Component} from 'react';

import './css/bootstrap.css';
import './css/product.css';
import Footer from './footer.js';
import {MainNavbar} from './navbar.js'



class About extends Component {
	constructor() {
    super();
    this.state = {
		
     
    };
  }
	
	render(){
		
		
		
    return (
		<div>
		<div className="bodymain">
			<MainNavbar/>
			<br/>
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<h2 className = "titlecol">About Us</h2>
					</div>
				</div>
				
				<div className="row addingmarginleft">
					<p>A company that led the way with technology in thermal combustion enhancement. </p>
					<p>We jointly developed a thermal enhancement technology using NG as a based fuel with an US proprietary additives manufacturer. After extensive trials, we launched our cutting-gas product ”EnviroGas”, in Singapore in 2009. </p>

					<p>For the past 30 years, acetylene has been the common oxy-fuel for metal cutting application in ship building, ship repair, and fabrication industries. This is mainly due to its high flame temperature (3100°C) and low density (0.9 that of air). </p>
					<p>With our gas treatment technology, we transform natural gas such that it can achieve higher cutting speed that will allow it to fully replace acetylene for shipyard and metal fabricator applications.
					</p>
					<p>To-date, most if not all, of the notable shipyards have since switched to EnviroGas for their cutting needs.</p>
					
				  </div>
			</div> 
			
		
			  
		</div>
		<Footer />
		</div>
		
		  	
  );
}}


export {About};
