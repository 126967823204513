// App.js
import React, {Component} from 'react';

import './css/bootstrap.css';
import './css/product.css';
import Footer from './footer.js';
import {MainNavbar} from './navbar.js';

import image1 from './img/gallery/1.jpg';
import image2 from './img/gallery/2.jpg';
import image3 from './img/gallery/010.jpg';
import image4 from './img/gallery/012.jpg';
import image5 from './img/gallery/013.jpg';
import image6 from './img/gallery/15.jpg';
import image8 from './img/gallery/17.jpg';
import image9 from './img/gallery/18.jpg';
import image10 from './img/gallery/19.jpg';
import image11 from './img/gallery/020.jpg';
import image13 from './img/gallery/022.jpg';
import image15 from './img/gallery/025.jpg';
import image16 from './img/gallery/026.jpg';
import image17 from './img/gallery/27.jpg';

class Gallery extends Component {
	constructor() {
    super();
    this.state = {
		showtab:0
     
    };
  }
	showThisTab = (tabnum) => {
	  var check = !isNaN(tabnum.target.id) && (function(x) { return (x | 0) === x; })(parseFloat(tabnum.target.id))
	  if (check === true){
		  this.setState({ showtab: parseInt(tabnum.target.id) });
		  
	  }
	  
  };
	
	render(){
		var detailist = [[image13, "CNG cylinders connected to a distributed manifold (regulator supplied by Enviro Gas)"], [image15, "Pipe cut using additive treated CNG"], [image16, "'Clean' finish look post-cutting using our additive treated CNG"], [image17, "CNG filing at transmission line connected station"]];
		var detailshow = [];
		for (var i = 0; i < detailist.length; i++) {
			
			detailshow.push(
				
				<div className="col-12 col-md-6 col-lg-4 grid-container">
						<div className="containergallery">
						  <img src={detailist[i][0]} alt="" class="imagegallery"/>
						  <div className="overlaygallery">
							<div className="textgallery">{detailist[i][1]}</div>
						  </div>
						</div>
						
					</div>
				
					  )
		}
		
		
		
		var cngenviro = [[image1, "Pressure letdown regulators & key components maintained by Enviro Gas)"], [image2, "Pressure letdown regulators & key components maintained by Enviro Gas)"]];
		var cngenviroshow = [];
		for (var j = 0; j < cngenviro.length; j++) {
			
			cngenviroshow.push(<div className="col-12 col-md-6 col-lg-4 grid-container">
									<div className="containergallery">
									  <img src={cngenviro[j][0]} alt="" class="imagegallery"/>
									  
									</div>

								</div>
					  )
		}
		
		
		var cnglist = [[image3, ""], [image4, ""], [image5, ""], [image6, ""], [image8, ""], [image9, ""], [image10, ""], [image11, ""]];
		var cngshow = [];
		for (var k = 0; k < cnglist.length; k++) {
			
			cngshow.push(<div className="col-12 col-md-6 col-lg-4 grid-container">
									<div className="containergallery">
									  <img src={cnglist[k][0]} alt="" class="imagegallery"/>
									  
									</div>

								</div>
					  )
		}
		


		
    return (
		
		<div>
		<div className="bodymain">
			<MainNavbar/>
			<br/>
			
		
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<h2 className = "titlecol">Photo Gallery</h2>
					</div>
				</div>
				<br/><br/>
				<div className="row">	
					{detailshow}
				
				</div>
				<br/><br/>
				<div className="row">
						<div className="col-12">
							<span className = "gallerytitle">Licensed CNG storage bay (pressure letdown regulators & key components maintained by Enviro Gas)</span>
						</div>

					</div>
				<br/><br/>
				<div className="row">	
					{cngenviroshow}

				</div>	
				<br/><br/>
				<div className="row">
						<div className="col-12">
							<span className = "gallerytitle">Licensed CNG storage bay</span>
						</div>

				</div>
				<br/><br/>
				<div className="row">	
					{cngshow}

				</div>	
				
				
			</div> 
			
			
			
			
		</div>
		<br/>
		
		<Footer />
		</div>
		
		  	
  );
}}


export {Gallery};
