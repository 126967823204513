// App.js
import React, {Component} from 'react';

import './css/bootstrap.css';
import './css/product.css';
import Footer from './footer.js';
import {MainNavbar} from './navbar.js';

import image1 from './img/cng/1.jpg';
import image2 from './img/cng/2.jpg';
import image3 from './img/cng/3.jpg';
import image4 from './img/cng/4.jpg';
import image5 from './img/cng/5.jpg';
import table1 from './img/cng/table1.jpg';
import table2 from './img/cng/table2.jpg';


class Cng extends Component {
	constructor() {
    super();
    this.state = {
		showtab:0
     
    };
  }
	showThisTab = (tabnum) => {
	  var check = !isNaN(tabnum.target.id) && (function(x) { return (x | 0) === x; })(parseFloat(tabnum.target.id))
	  if (check === true){
		  this.setState({ showtab: parseInt(tabnum.target.id) });
		  
	  }
	  
  };
	
	render(){
		var cngenviro = [image1, image2, image3, image4, image5];
		var cngenviroshow = [];
		for (var i = 0; i < cngenviro.length; i++) {
			
			cngenviroshow.push(
							<div className="row cngspacing">
								<div className="col-0 col-md-2"/>
								<div className="col-12 col-md-8 grid-container">
									<div className="containergallery">
									  <img src={cngenviro[i]} alt="" class="imagegallery"/>

									</div>

								</div>
								
							</div>
								
					  )
		}


		
    return (
		
		<div>
		<div className="bodymain">
			<MainNavbar/>
			<br/>
			
		
			<div className="container">
				<div className="row">
					<div className="col-12">
						<h2 className = "titlecol">Treated CNG (Cutting Applications)</h2>
					</div>
				</div>
				
				<div className="row mx-auto">
					<div className="col-md-12">
						<span className = "">With the new additive technology from Enviro Gas, it is now possible to raise the flame temperature for natural gas to a comparable level with acetylene while <span className = "emphasis">maintaining the characteristic of base fuel (NG).</span></span><br/><br/>
						<span className = "oemtitle">Principles of Radiation Suppression</span><br/>
						<span className = "">Our additive enables the suppression of radiant energy, thereby, causing higher temperatures in the primary flame with less radiant cooling of the flame. </span>
						<br/><br/>
						<span className = "oemtitle">Benefits of EnviroGas:</span><br/>
						<span className = "emphasis">Safer than Acetylene </span>
						<br/>
						<ul className="list oemlist">
							<li>EnviroGas has an ignition temperature of about 600°C (which is approximately two times higher than acetylene's ignition temperature). This means that the risk of an explosion, deflagration or a fire is much lower than compared with acetylene</li>
							<li>Flammability limit of natural gas in air is 5% to 14% while acetylene is 2% to 80%</li>
							<li>It is much lighter than air (specific gravity =0.6), hence it will dissipate fast into the atmosphere</li>
							<li>Tendency for flashback is very low (lower flame speed!) as compared to acetylene</li>
							<li>Supply pressure at pipeline can be adjusted according to the plant requirement. Acetylene is generally limited with 15 psig</li>
						</ul>
						<br/><br/>
						<span className = "oemtitle">Benefits to Environment (when switching from acetylene to treated CNG)</span><br/>
						<ul className="list oemlist">
							<li>Avoid acetone & DMF disposal</li>
							<li>Avoid slurry water and toxic gas disposal</li>
							<li>Lower carbon emissions: A typical shipyard using 200,000m<sup>3</sup>/yr—will be able to bring about reduction of 2,000 carbon tons by switching to EnviroGas</li>
						</ul>
						<br/><br/>
						<span className = "oemtitle">Physical Properties</span><br/>
					</div>
				</div>
				<br/>
				<div className="row">
					<div className="col-md-12">
						<img loading="lazy" className="oemimage" src={table1} alt="EnviroGas v Acetylene" />
					</div>
				</div>
				<br/>
				<div className="row">
					<div className="col-md-12">
						<img loading="lazy" className="oemimage" src={table2} alt="Gas comparison" />
					</div>
						
				</div>
				
				<br/><br/>
				{cngenviroshow}
				
				
				
				
			</div> 
			
			
			
			
		</div>
		
		<Footer />
		</div>
		
		  	
  );
}
}


export {Cng};
