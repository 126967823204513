// App.js
import React, {Component} from 'react';

import { Icon } from '@iconify/react';
import './css/bootstrap.css';
import './css/product.css';
import Footer from './footer.js';
import {MainNavbar} from './navbar.js'

import keppel from './img/partners/keppel.png';
import sembcorp from './img/partners/sembcorp.png';
import sapura from './img/partners/sapura.png';
import st from './img/partners/st.png';
import sembawang from './img/partners/sembawang.png';
import ppl from './img/partners/ppl.png';
import ddw from './img/partners/ddw.png';


class Contact extends Component {
	constructor() {
    super();
    this.state = {
		
     
    };
  }
	
	render(){
		
		var logolist = [[keppel, "Keppel Logo"], [sembcorp, "Sembcorp Logo"], [sapura, "Sapura Logo"], [sembawang, "Sembawang Logo"], [ddw, "DDW Logo"], [ppl, "PPL Logo"], [st, "ST Logo"]];
		var logoshow = [];
		for (var i = 0; i < logolist.length; i++) {
			logoshow.push(<div className="col-6 col-lg-4 py-3 text-center">
									<img loading="lazy" className="logo-imagebrand" src={logolist[i][0]} alt={logolist[i][1]}></img>
								</div>
					  )
		}
		/* <div className="row"> {logoshow}</div> */
		
		
    return (
		<div>
		<div className="bodymain">
			<MainNavbar/>
			<br/>
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<h2 className = "titlecol">Contact Us</h2>
					</div>
				</div>
				
				<br/>
			</div> 
			<div className="container">
				<div className="row">
					<div className="col-0 col-md-3">
					</div>
					<div className="col-12 col-md-6">
						<span className = "contacttitle">Singapore Office</span>
					</div>
					<br/><br/>
				</div>
				<div className="row">
					<div className="col-0 col-md-3">
					</div>
					<div className="col-12 col-md-6">
						<span className = "contact"><span className = "thumbnail"><Icon width = "25" icon="mdi:address-marker-outline" /></span>26 Tanjong Kling Road, Singapore 628051</span>
					</div>
					
				</div>
				<br/>
				<div className="row">
					<div className="col-0 col-md-3">
					</div>
					<div className="col-12 col-md-6">
						<span className = "contact"><span className = "thumbnail"><Icon width = "25" icon="clarity:phone-handset-line" /></span>+65 6265 2792</span>
					</div>
				</div>
				<br/>
				<div className="row">
					<div className="col-0 col-md-3">
					</div>
					<div className="col-12 col-md-6">
						<span className = "contact"><span className = "thumbnail"><Icon width = "25" icon="material-symbols:alternate-email" /></span>enquiry@envirogasasia.com</span>
					</div>
				</div>
			</div> 
			<br/><br/>
			<div className="container">
				<div className="row">
					<div className="col-0 col-md-3">
					</div>
					<div className="col-12 col-md-6">
						<span className = "contacttitle">Malaysia Office</span>
					</div>
					<br/><br/>
				</div>
				
				<div className="row">
					<div className="col-0 col-md-3">
					</div>
					<div className="col-12 col-md-6">
						<span className = "contact"><span className = "thumbnail"><Icon width = "25" icon="material-symbols:person-outline" /></span>Renai Enviro (M) Sdn. Bhd</span>
					</div>
					
				</div>
				<br/>
				<div className="row">
					<div className="col-0 col-md-3">
					</div>
					<div className="col-12 col-md-6">
						<span className = "contact"><span className = "thumbnail"><Icon width = "25" icon="mdi:address-marker-outline" /></span>Warehouse 1, Lot 10 & 10 A, Jalan Tandang, Section 51, Selangor, 46050 Petaling Jaya, Malaysia</span>
					</div>
					
				</div>
				<br/>
				<div className="row">
					<div className="col-0 col-md-3">
					</div>
					<div className="col-12 col-md-6">
						<span className = "contact"><span className = "thumbnail"><Icon width = "25" icon="clarity:phone-handset-line" /></span>+60 3-7785 3933</span>
					</div>
				</div>
				
			</div> 

			
		
			  
		</div>
		<br/><br/>
		<Footer />
		</div>
		
		  	
  );
}}


export {Contact};
