// App.js
import React from 'react';
import { Routes, Route } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

import Home from './js/home.js';
import {Partners} from './js/partners.js';
import {Contact} from './js/contact.js';

import {Gallery} from './js/gallery.js';
import {Videogallery} from './js/videogallery.js';
import {Cng} from './js/cng.js';
import {Oem} from './js/oem.js';
import {About} from './js/about.js';





function App() {
	
  return (
	  <div>
	  <Routes>
	  <Route path="/" element={<Home />} />
	  <Route path="partners" element={<Partners />} />
	  <Route path="contact" element={<Contact />} />
	  <Route path="gallery" element={<Gallery />} />
	  <Route path="video-gallery" element={<Videogallery />} />
	  <Route path="cng" element={<Cng />} />
	  <Route path="oem" element={<Oem />} />
	  <Route path="about-us" element={<About />} />
	  
	 
	  
	  
	  </Routes>
	  </div>
	  
	  
  );
}
export default App;