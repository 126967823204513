// App.js
import React, {Component} from 'react';

import './css/bootstrap.css';
import './css/home.css';
import Footer from './footer.js';
import {MainNavbar} from './navbar.js';
import image1 from './img/banner/add.png';


class MainHome extends Component {
	constructor() {
    super();
    this.state = {
     
    };
  }
	startNow = () => {
    this.props.changePage(1);
  };
	render(){ 
		
		
		
		
		
    return (
		<header className="header">
			<div className="container">
				<div className="row">
					<div className="col-10 col-sm-8 col-lg-6">
						<div className="text-container">
							<br/>
							<p className="headermodified">A Global Technology In Thermal Combustion Enhancement<br/></p>
							<br/>
							<p className="smallerheader"> Envirogas specializes in treatment and supply of industrial compressed natural gas (CNG). Our gas treatment enables natural gas to achieve cutting performance to fully replace acetylene and LPG for shipyard and metal fabricator applications.<br/></p>
						</div> 
					</div> 
				</div> 
				<div className="row">
					<div className="col-md-12">
						<img loading="lazy" className="circlesimage" src={image1} alt="EnviroGas images" />
					</div>
				</div>
				<br/>
				<Footer abs={true}/>
				
				
			</div> 
		</header>
		
		 
  );
}}




class Home extends Component {
	constructor() {
    super();
    this.state = {
		page : 0,
    };
  }

  showPage = (page) => {
    this.setState({ page: page });
  };

	
	
	render(){
		
		
		
    return (
	 <div className="bodymain">
		<MainNavbar/>
		 <MainHome changePage = {this.showPage}/>
    </div>
  );
}}

export default Home;