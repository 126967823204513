// App.js
import React, {Component} from 'react';

import './css/bootstrap.css';
import './css/product.css';
import Footer from './footer.js';
import {MainNavbar} from './navbar.js'


class Videogallery extends Component {
	constructor() {
    super();
    this.state = {
		
     
    };
  }
	
	render(){
		
		var detailist = [["https://www.youtube.com/embed/P0iJG93zMNk", "80mm Rig Leg Cutting", "Rig leg cutting done at Keppel Shipyard."], ["https://www.youtube.com/embed/j4yFvu-SEsI", "Direct Additive Dousing", "Direct additive dousing done at Keppel Shipyard."], ["https://www.youtube.com/embed/ergjS_AkfWc", "Best Cutting Flame", "With our additive acting as a fuel enhancer, it will allow natural gas to be able to cut thick plates."]];
		var logoshow = [];
		for (var i = 0; i < detailist.length; i++) {
			logoshow.push(<div className="col-12 col-md-6">
									<div className="card">
										<iframe title = {detailist[i][1]} className="embed-responsive-item" src = {detailist[i][0]}></iframe>
										<div className="card-body">
										  <h4> {detailist[i][1]} </h4>
										  <p>{detailist[i][2]}</p>
										</div>
									 </div>
								</div>
					  )
		}
		/* <div className="row"> {logoshow}</div> */
		
		
    return (
		<div>
		<div className="bodymain">
			<MainNavbar/>
			<br/>
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<h2 className = "titlecol">Video Gallery</h2>

					</div>
				</div>
				<br/>
				<br/>
				<div className="row"> {logoshow}</div>
				
				
			</div> 

			
		
			  
		</div>
		<br/>
		<Footer />
		</div>
		
		  	
  );
}}


export {Videogallery};
