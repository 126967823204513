// App.js
import React, {Component} from 'react';

import './css/bootstrap.css';
import './css/product.css';
import Footer from './footer.js';
import {MainNavbar} from './navbar.js'

import keppel from './img/partners/keppel.png';
import sembcorp from './img/partners/sembcorp.png';
import sapura from './img/partners/sapura.png';
import st from './img/partners/st.png';
import sembawang from './img/partners/sembawang.png';
import ppl from './img/partners/ppl.png';
import ddw from './img/partners/ddw.png';


class Partners extends Component {
	constructor() {
    super();
    this.state = {
		
     
    };
  }
	
	render(){
		
		var logolist = [[keppel, "Keppel Logo"], [sembcorp, "Sembcorp Logo"], [sapura, "Sapura Logo"], [sembawang, "Sembawang Logo"], [ddw, "DDW Logo"], [ppl, "PPL Logo"], [st, "ST Logo"]];
		var logoshow = [];
		for (var i = 0; i < logolist.length; i++) {
			logoshow.push(<div className="col-12 col-sm-6 col-lg-4 py-3 text-center partnersspace">
									<img loading="lazy" className="logo-imagebrand" src={logolist[i][0]} alt={logolist[i][1]}></img>
								</div>
					  )
		}
		/* <div className="row"> {logoshow}</div> */
		
		
    return (
		<div>
		<div className="bodymain">
			<MainNavbar/>
			<br/>
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<h2 className = "titlecol">Our Partners</h2>

					</div>
				</div>
				<br/>
				<br/>
				<div className="row"> {logoshow}</div>
				
				
			</div> 

			
		
			  
		</div>
		<Footer />
		</div>
		
		  	
  );
}}


export {Partners};
